@import "../colors/theme.css";

html {
	font-family: "Segoe UI", -apple-system, "Verdana", sans-serif;
}

hr {
	border: none;
	border-top: 1px solid white;
}

body {
	width: 100vw;
	box-sizing: border-box;
	overflow-x: hidden;
	background-color: #232323;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: white;
	background: radial-gradient(circle at 0 0, darken(#202334, 5), #202334 100%);
}

a {
	text-decoration: none;
}

nav {
	@media screen and (min-width: 1000px) {
		min-height: 100vh;
		width: 350px;
		min-width: 350px;
		max-height: 100vh;
		//border-right: 1px solid var(--md-sys-color-secondary);
	}
	@media screen and (max-width: 999px) {
		min-width: 100vw;
		width: 100vw;
		//border-bottom: 1px solid var(--md-sys-color-secondary);
	}
	// background: radial-gradient(circle at 0 0, darken(#2b3145, 10), #2b3145 100%);

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding-top: 1rem;

	overflow-y: auto;
	padding-bottom: 10px;

	box-sizing: border-box;
}

main {
	@media screen and (min-width: 1000px){
		max-height: 100vh;
	}

	overflow-y: auto;
	flex-grow: 1;
	padding: 20px 20px;
	box-sizing: border-box;
}

h1 {
	font-weight: lighter;
}

h2 {

}

h3 {
	margin-top: 30px;
	margin-bottom: 10px;
	font-size: 1.7rem;
}

button {
	cursor: pointer;
	color: white;
}
