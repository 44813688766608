.Details {
	max-width: 100%;

	text-align: left;

	padding: 0 1rem;

	summary {
		user-select: none;
	}

	select {
		display: block;
		padding: 8px 5px;
		appearance: auto;


		&:first-child {
			border-radius: 5px 0 0 5px;
			grid-column: 1 / span 1;
			grid-row: 1 / span 1;

			@media (max-width: 750px) {
				border-radius: 5px 5px 0 0;
			}
		}

		&:last-child {
			border-radius: 0 5px 5px 0;
			grid-column: 2 / span 1;
			grid-row: 1 / span 1;


			@media (max-width: 750px) {
				border-radius: 0 0 5px 5px;
				grid-column: 1 / span 1;
				grid-row: 2 / span 1;
			}
		}
	}

	&__ {
		&name {
			font-size: 3rem;
			font-weight: lighter;
			margin-top: 5px;
			margin-bottom: 5px;
		}

		&members {
			display: block;
			margin-top: 15px;
		}

		&loading-text {
			display: block;
			margin: 0 auto 5px;
		}

		&loading {
			display: flex;
			flex-direction: column;
			margin-top: 30px;
		}

		&loading-bar {
			width: 100%;
			border: 1px solid white;
			border-radius: 0;
			appearance: none;
			height: 22px;

			&::-webkit-progress-bar {
				background-color: transparent;
			}

			&::-webkit-progress-value {
				background-color: white;
			}
		}
	}
}
