:root {
  --md-source: #37675f;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #00201c;
  --md-ref-palette-primary20: #003731;
  --md-ref-palette-primary25: #00443c;
  --md-ref-palette-primary30: #005047;
  --md-ref-palette-primary35: #005d53;
  --md-ref-palette-primary40: #006b5f;
  --md-ref-palette-primary50: #008678;
  --md-ref-palette-primary60: #00a392;
  --md-ref-palette-primary70: #2ebfac;
  --md-ref-palette-primary80: #54dbc8;
  --md-ref-palette-primary90: #75f8e4;
  --md-ref-palette-primary95: #b4fff1;
  --md-ref-palette-primary98: #e5fff8;
  --md-ref-palette-primary99: #f2fffb;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #06201c;
  --md-ref-palette-secondary20: #1c3530;
  --md-ref-palette-secondary25: #27403b;
  --md-ref-palette-secondary30: #334b47;
  --md-ref-palette-secondary35: #3e5752;
  --md-ref-palette-secondary40: #4a635e;
  --md-ref-palette-secondary50: #627c77;
  --md-ref-palette-secondary60: #7c9690;
  --md-ref-palette-secondary70: #96b1ab;
  --md-ref-palette-secondary80: #b1ccc6;
  --md-ref-palette-secondary90: #cce8e2;
  --md-ref-palette-secondary95: #dbf7f0;
  --md-ref-palette-secondary98: #e5fff8;
  --md-ref-palette-secondary99: #f2fffb;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #001e30;
  --md-ref-palette-tertiary20: #143349;
  --md-ref-palette-tertiary25: #213e54;
  --md-ref-palette-tertiary30: #2d4a60;
  --md-ref-palette-tertiary35: #39556d;
  --md-ref-palette-tertiary40: #456179;
  --md-ref-palette-tertiary50: #5e7a93;
  --md-ref-palette-tertiary60: #7794ae;
  --md-ref-palette-tertiary70: #91afc9;
  --md-ref-palette-tertiary80: #accae5;
  --md-ref-palette-tertiary90: #cbe6ff;
  --md-ref-palette-tertiary95: #e7f2ff;
  --md-ref-palette-tertiary98: #f7f9ff;
  --md-ref-palette-tertiary99: #fcfcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #191c1b;
  --md-ref-palette-neutral20: #2d3130;
  --md-ref-palette-neutral25: #383c3b;
  --md-ref-palette-neutral30: #444746;
  --md-ref-palette-neutral35: #505352;
  --md-ref-palette-neutral40: #5c5f5e;
  --md-ref-palette-neutral50: #747876;
  --md-ref-palette-neutral60: #8e9190;
  --md-ref-palette-neutral70: #a9acaa;
  --md-ref-palette-neutral80: #c4c7c5;
  --md-ref-palette-neutral90: #e0e3e1;
  --md-ref-palette-neutral95: #eff1ef;
  --md-ref-palette-neutral98: #f7faf8;
  --md-ref-palette-neutral99: #fafdfb;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #141d1b;
  --md-ref-palette-neutral-variant20: #293230;
  --md-ref-palette-neutral-variant25: #343d3b;
  --md-ref-palette-neutral-variant30: #3f4946;
  --md-ref-palette-neutral-variant35: #4b5452;
  --md-ref-palette-neutral-variant40: #56605e;
  --md-ref-palette-neutral-variant50: #6f7976;
  --md-ref-palette-neutral-variant60: #899390;
  --md-ref-palette-neutral-variant70: #a3adaa;
  --md-ref-palette-neutral-variant80: #bec9c5;
  --md-ref-palette-neutral-variant90: #dae5e1;
  --md-ref-palette-neutral-variant95: #e9f3ef;
  --md-ref-palette-neutral-variant98: #f1fcf8;
  --md-ref-palette-neutral-variant99: #f4fffb;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #006b5f;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #75f8e4;
  --md-sys-color-on-primary-container-light: #00201c;
  --md-sys-color-secondary-light: #4a635e;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #cce8e2;
  --md-sys-color-on-secondary-container-light: #06201c;
  --md-sys-color-tertiary-light: #456179;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #cbe6ff;
  --md-sys-color-on-tertiary-container-light: #001e30;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fafdfb;
  --md-sys-color-on-background-light: #191c1b;
  --md-sys-color-surface-light: #fafdfb;
  --md-sys-color-on-surface-light: #191c1b;
  --md-sys-color-surface-variant-light: #dae5e1;
  --md-sys-color-on-surface-variant-light: #3f4946;
  --md-sys-color-outline-light: #6f7976;
  --md-sys-color-inverse-on-surface-light: #eff1ef;
  --md-sys-color-inverse-surface-light: #2d3130;
  --md-sys-color-inverse-primary-light: #54dbc8;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #006b5f;
  --md-sys-color-outline-variant-light: #bec9c5;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #54dbc8;
  --md-sys-color-on-primary-dark: #003731;
  --md-sys-color-primary-container-dark: #005047;
  --md-sys-color-on-primary-container-dark: #75f8e4;
  --md-sys-color-secondary-dark: #b1ccc6;
  --md-sys-color-on-secondary-dark: #1c3530;
  --md-sys-color-secondary-container-dark: #334b47;
  --md-sys-color-on-secondary-container-dark: #cce8e2;
  --md-sys-color-tertiary-dark: #accae5;
  --md-sys-color-on-tertiary-dark: #143349;
  --md-sys-color-tertiary-container-dark: #2d4a60;
  --md-sys-color-on-tertiary-container-dark: #cbe6ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #191c1b;
  --md-sys-color-on-background-dark: #e0e3e1;
  --md-sys-color-surface-dark: #191c1b;
  --md-sys-color-on-surface-dark: #e0e3e1;
  --md-sys-color-surface-variant-dark: #3f4946;
  --md-sys-color-on-surface-variant-dark: #bec9c5;
  --md-sys-color-outline-dark: #899390;
  --md-sys-color-inverse-on-surface-dark: #191c1b;
  --md-sys-color-inverse-surface-dark: #e0e3e1;
  --md-sys-color-inverse-primary-dark: #006b5f;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #54dbc8;
  --md-sys-color-outline-variant-dark: #3f4946;
  --md-sys-color-scrim-dark: #000000;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
