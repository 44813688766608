.Diagram {
	&__ {
		&selection {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 5px;

			button[type=submit] {
				padding: 8px 5px;
				margin-top: 10px;
				background-color: #888;
				color: white;
				border: 1px solid white;
				border-radius: 5px;
				cursor: pointer;

				&:hover {
					background-color: #aaa;
					border: 1px solid white;
				}
			}

			h3 {
				margin: 10px 0 5px;
			}
		}

		&dropdown-wrapper {
			display: grid;

			grid-auto-columns: 2fr 1fr;
			grid-auto-rows: 36px;
			@media (max-width: 750px) {
				grid-auto-columns: 1fr;
				grid-auto-rows: 36px 36px;
			}
		}

		&chart {
			height: 500px;
		}

		&hint {
			color: #666666;
			font-size: 0.7rem;
			margin-top: 5px;
			user-select: none;
		}

		&table {
			width: 100%;

			&- {
				&wrapper {
					max-width: 100%;
					overflow-x: auto;
				}
			}
		}

		&block {
			margin-top: 20px;
			max-width: 700px;
		}
	}
}
