.Search {
	width: 100%;
	margin-bottom: 10px;

	&__ {
		&form {
			display: flex;
			flex-direction: column;
		}

		&label {
			text-align: center;
			display: block;
		}

		&input, &submit {
			padding: 12px 15px;
			border: none;
			border-radius: 0;
			margin-bottom: 0;
		}

		&submit {
			appearance: none;
			background-color: #56605E;
			color: white;
			font-weight: normal;
			margin-top: 0;
			&:hover, &:active {
				background-color: #888;
			}
		}

		&hint {
			margin-top:-20px;
			margin-bottom: 20px;
		}
	}
}
