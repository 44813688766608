.Dropdown {
	display: flex;
	flex-direction: column;
	align-items: center;

	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: white;
		// -webkit-border-radius: 1ex;
		// -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
	}

	&::-webkit-scrollbar-corner {
		background: #000000;
	}

	&__ {
		&element {
			background-color: var(--md-sys-color-tertiary-container);
			box-sizing: border-box;
			// border-top: 1px solid #DDDDDD;
			// border-bottom: 1px solid #DDDDDD;
			// border-right: 0;
			// border-left: 0;
			border: none;
			user-select: none;
			padding: 10px 12px;
			text-align: left;
			font-size: 1rem;
			color: white;
			border-radius: 4px;
			width: 90%;

			margin-top: 10px;


			display: flex;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;

			box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

			&:hover {
				background-color: var(--md-sys-color-tertiary);
				color: black;
			}

			&-- {
				&inactive {
					opacity: 0.5;
					cursor: inherit;

					&:hover {
						background-color: #333333;
					}
				}

				&clan {
					align-items: center;
					font-size: 1.5rem;
					margin-top: 20px;
				}
			}
		}

		&header {
			font-size: 1.2rem;
			display: block;
			margin-top: 10px;
			text-align: center;
		}

		&name {
			display: block;
		}

		&motto {
			display: block;
			opacity: 0.4;
			font-size: 0.8em;
		}
	}
}
